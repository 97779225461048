import "./service-tile.scss";

import React, { useState, useEffect } from "react";
import { IconContext } from "react-icons";
import classNames from "classnames";

const ServiceTile = ({ image, icon, title, text, onClick }) => {
	const [hover, setHover] = useState(false);
	const [isTouchDevice, setIsTouchDevice] = useState(false);

	const hoverHandler = () => {
		setHover(!hover);
	};

	useEffect(() => {
		if (
			"ontouchstart" in window ||
			navigator.maxTouchPoints > 0 ||
			navigator.msMaxTouchPoints > 0
		) {
			setIsTouchDevice(true);
		}
	});

	return (
		<IconContext.Provider
			value={{ color: "#fff7f7", className: "react-icon" }}
		>
			<div
				className="service-tile"
				onMouseEnter={() => hoverHandler()}
				onMouseLeave={() => hoverHandler()}
			>
				<img
					src={require(`../../assets/images/photos/${image}`).default}
					alt=""
					className="service-tile__image"
					loading="lazy"
				/>
				<div
					className={classNames("service-tile__title-label", {
						"service-tile__title-label--hidden": hover,
						"service-tile__title-label--touch": isTouchDevice,
					})}
				>
					{icon}
					<h4 className="service-tile__title">{title}</h4>
					{isTouchDevice && (
						<button onClick={onClick} className="btn btn--white">
							Czytaj
						</button>
					)}
				</div>
				<div
					className={classNames("service-tile__text-container", {
						visible: hover,
					})}
				>
					<h4 className="service-tile__title">{title}</h4>
					<p className="service-tile__text">{text}</p>
					<button onClick={onClick} className="btn btn--white">
						Czytaj
					</button>
				</div>
			</div>
		</IconContext.Provider>
	);
};

export default ServiceTile;
