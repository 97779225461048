import "./layout.scss";
import React, { useEffect, useMemo, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import ScrollUp from "../common/ScrollUp";
import { Snow } from "../common/Snow/Snow";

const Layout = ({ children }) => {
	const [scrolled, setScrolled] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			window.addEventListener("scroll", handleScroll);
			if (window.scrollY !== 0) {
				setScrolled(true);
			} else {
				setScrolled(false);
			}
		};
		handleScroll();
	}, []);

	const isDecemberOrJanuary = useMemo(() => {
		const currentDate = new Date();
		const currentMonth = currentDate.getMonth();

		return currentMonth === 11 || currentMonth === 0;
	}, []);

	return (
		<>
			<Header scrolled={scrolled} />
			{isDecemberOrJanuary ? <Snow /> : null}
			<main>{children}</main>
			<ScrollUp scrolled={scrolled} />
			<Footer />
		</>
	);
};

export default Layout;
