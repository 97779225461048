import { useState } from "react";

export const useMousePosition = () => {
	const [position, setPosition] = useState({ x: 0, y: 0 });

	const handleMouseMove = (e) => {
		e.persist();
		setPosition({ ...position, x: e.clientX, y: e.clientY });
	};
	return {
		x: Math.floor(position.x / 25),
		y: Math.floor(position.y / 15),
		handleMouseMove,
	};
};
