import React from "react";
import "./snow.scss";

export const Snow = () => {
	return (
		<div className="snow-wrapper">
			{Array(50)
				.fill()
				.map((index) => {
					return <div className="snow" key={index} />;
				})}
		</div>
	);
};
