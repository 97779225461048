import "./section-title.scss";

import classNames from "classnames";
import React from "react";

const SectionTitle = ({ title, subtitle, variant, white }) => {
	return (
		<div
			className={classNames("section-title", {
				"section-title--left": variant === "left",
				"section-title--center": variant === "center",
				"section-title--right": variant === "right",
				"section-title--white": white,
			})}
		>
			<div className="section-title__titles-container">
				<span className="section-title__subtitle">{subtitle}</span>
				<h3 className="section-title__title">{title}</h3>
				<span className="section-title__divider" />
			</div>
		</div>
	);
};

export default SectionTitle;
