import "./mobile-menu.scss";

import React, { useEffect, useState } from "react";
import classNames from "classnames";

import { nav } from "../../data/nav";

const MobileMenu = ({ className }) => {
	const [active, setActive] = useState(false);

	const handleClick = () => {
		setActive((state) => !state);
	};

	useEffect(() => {
		const body = document.querySelector("body");
		active
			? (body.style.overflow = "hidden")
			: (body.style.overflow = "auto");
	}, [active]);

	return (
		<>
			<div
				className={
					`${className} ` +
					classNames("mobile-menu__hamburger", {
						"mobile-menu__hamburger--active": active,
					})
				}
				onClick={() => handleClick()}
			>
				<span className="mobile-menu__hamburger-line" />
				<span className="mobile-menu__hamburger-line" />
				<span className="mobile-menu__hamburger-line" />
			</div>
			<div
				className={classNames("mobile-menu__window", {
					"mobile-menu__window--active": active,
				})}
			>
				<ul className="mobile-menu__window-list">
					{nav.items.map((item, index) => (
						<li
							className="mobile-menu__window-list-item"
							key={index}
							onClick={() => handleClick()}
						>
							<a href={item.link} title={item.label}>
								{item.label}
							</a>
						</li>
					))}
				</ul>
			</div>
		</>
	);
};

export default MobileMenu;
