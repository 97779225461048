import classNames from "classnames";
import "./icon-tile.scss";

import React, { useState } from "react";
import { IconContext } from "react-icons";
import ReactVisibilitySensor from "react-visibility-sensor";

const IconTile = ({ icon, title, text, iconIndex }) => {
	const [isVisible, setIsVisible] = useState(false);

	const onChange = () => {
		setTimeout(() => setIsVisible(true), 1000 + 100 * iconIndex);
		setTimeout(() => setIsVisible(false), 1500 + 100 * iconIndex);
	};

	return (
		<IconContext.Provider
			value={{ color: "#ad5448", className: "react-icon" }}
		>
			<ReactVisibilitySensor onChange={onChange}>
				<div
					className={classNames("icon-tile", {
						"icon-tile__hovered": isVisible,
					})}
				>
					<div className="icon-tile__icon">{icon}</div>
					<div className="icon-tile__content">
						<h4 className="icon-tile__title">{title}</h4>
						<div
							className="icon-tile__text"
							dangerouslySetInnerHTML={{ __html: text }}
						/>
					</div>
					<div className="icon-tile__icon-shadow">{icon}</div>
				</div>
			</ReactVisibilitySensor>
		</IconContext.Provider>
	);
};

export default IconTile;
