/* eslint-disable quotes */
import React from "react";
import { FaBuilding } from "react-icons/fa";
import { ImBook } from "react-icons/im";
import {
	BsFillCheckCircleFill,
	BsCalculatorFill,
	BsFillPeopleFill,
	BsFillBriefcaseFill,
} from "react-icons/bs";
import { BiCopyAlt } from "react-icons/bi";
import { IoBuild } from "react-icons/io5";

export const home = {
	iconGroup: [
		// {
		// 	icon: <FaHandPointRight />,
		// 	title: "Administracja",
		// 	text: "Labore possimus est a vero voluptatibus et laudantium dolorum illo, asperiores repellat ex earum ea reprehenderit",
		// },
		{
			icon: <FaBuilding />,
			title: "Nieruchomości",
			text: "<ul style='list-style: inside'><li>obsługa eksploatacyjna nieruchomości</li><li>obsługa techniczna nieruchomości</li><li>obsługa finansowa nieruchomości</li><li>koordynacja działań i prac na nieruchomości</li><li>kontakt z właścicielem 24h</li></ul>",
		},
		{
			icon: <ImBook />,
			title: "Usługi księgowe",
			text: "<ul style='list-style: inside'><li>prowadzenie ksiąg rachunkowych</li><li>pełna księgowość, sporządzanie sprawozdań, deklaracji</li><li>prowadzenie księgi przychodów i rozchodów</li><li>prowadzenie kadr i płac</li></ul>",
		},
	],
	sectionAbout: {
		first: {
			text: "<p>ADProfit specjalizuje się w pełnym zarządzaniu i administrowaniu:</p><ul><li>nieruchomości,</li><li>wspólnot Mieszkaniowych,</li><li>lokali komercyjnych.</li></ul><p>Profesjonalnie i kompleksowo prowadzone czynności zarządzania są wykonywane przez licencjonowanych zarządców nieruchomości będącymi członkami Małopolskiego Stowarzyszenia Pośredników w Obrocie Nieruchomościami posiadających Świadectwo nadania licencji Polskiej Federacji Rynku Nieruchomości w zakresie zarządzania Nieruchomościami oraz ubezpieczenie OC z tytułu prowadzonej działalności zawodowej.</p>",
		},
		second: {
			text: "<p>Dla Wspólnot Mieszkaniowych, które mają siedzibę z dala od naszego biura i gdy będzie taka potrzeba, możemy prowadzić dyżur w siedzibie danej Wspólnoty Mieszkaniowej.</p><p>Nasze usługi świadczymy na terenie i okolicach Krakowa.</p>",

			blockquote:
				"Bezpośredni kontakt, dbałość o Państwa potrzebny, zadowolenie jak i pozytywne relacje są dla nas najistotniejsze.",
		},
	},
	sectionServices: {
		intro: "Otwartość na współpracę, rzetelność i uczciwość pozwalają nam oferować usługi wysokiej jakości dopasowane do indywidualnych potrzeb klientów.",
		services: [
			{
				id: "wspolnoty-mieszkaniowe",
				image: "section-services-1.jpg",
				icon: <BsFillCheckCircleFill />,
				title: "Wspólnoty mieszkaniowe",
				text: "Rzetelna i profesjonalna obsługa w zakresie zarządzania wspólnotami mieszkaniowymi",
				modal: {
					texts: [
						"Ad Profit Administracja Finanse zapewnia rzetelną i profesjonalną obsługę w zakresie zarządzania <strong>wspólnotami mieszkaniowymi</strong>. Profesjonalnie i kompleksowo prowadzimy czynności zarządzania przez licencjonowanych zarządców nieruchomości posiadających Świadectwo nadania licencji Polskiej Federacji Rynku Nieruchomości w zakresie zarządzania Nieruchomościami oraz ubezpieczenie OC z tytułu prowadzonej działalności zawodowej.",
						"<strong>Podstawowy zakres obowiązków, to m.in:</strong> <ul><li>Obsługa i kontrola techniczna obiektu;</li><li>Obsługa administracyjna</li><li>Obsługa księgowa</li><li>Obsługa finansowa</li><li>Obsługa w zakresie doradztwa związanego z eksploatacją nieruchomości</li><li>oraz dodatkowe czynności określone w umowie na życzenie Klienta</li></ul>",
						"Szczegółowy zakres obowiązków jest dla Klienta przygotowywany zgodnie z jego oczekiwaniami w formie elektronicznej bądź papierowej.",
						"Do decyzji Klienta należy wybór formy współpracy w zależności od zakresu obowiązków i uprawnień: <ul><li>powierzenie zarządu nieruchomością wspólną i zlecenie zarządzania;</li><li>zlecenie administrowania.</li></ul>",
					],
				},
			},
			{
				id: "lokale-komercyjne",
				image: "section-services-2.jpg",
				icon: <BiCopyAlt />,
				title: "Lokale komercyjne",
				text: "Rzetelna i profesjonalna obsługa w zakresie zarządzania lokalnami komercyjnymi.",
				modal: {
					texts: [
						"Szczegółowy zakres obowiązków (oferta) przygotowywany jest indywidualnie, zgodnie z Klienta oczekiwaniami oraz rodzajem lokalu komercyjnego. Precyzyjną ofertę przedstawiamy w formie elektronicznej bądź papierowej, zgodnie z Państwa życzeniem.",
						"Lokale komercyjne obejmujemy kompleksowym zarządzaniem jak i również administrowaniem nieruchomości wraz z obsługa techniczną oraz księgową. Jesteśmy otwarci, posiadamy szeroki zakres czynności jakie oferujemy podczas współpracy.",
						"Dodatkowy zakres czynności jest ustalany indywidualnie zgodnie z wymaganiami Klienta.",
						"<strong>Zapraszamy do kontaktu, chętnie przygotujemy dla Państwa ofertę indywidualną, zgodną z Państwa oczekiwaniami.</strong>",
					],
				},
			},
			{
				id: "biuro-rachunkowe",
				image: "section-services-3.jpg",
				icon: <BsCalculatorFill />,
				title: "Biuro rachunkowe",
				text: "Pełny pakiet usług w zakresie księgowości, kadr i płac",
				modal: {
					texts: [
						"<strong>Oferujemy:</strong>",
						"Profesjonalnie, rzetelnie i kompleksowo prowadzone usługi księgowe przez doświadczonych księgowych.",
						"Pełen pakiet usług w zakresie księgowości, kadr i płac.",
						"Stały kontakt, profesjonalizm i zadowolenie z obsługi Klientów to nasze atuty.",
						"<strong>Zapraszamy do kontaktu, chętnie przygotujemy dla Państwa ofertę indywidualną, zgodną z Państwa oczekiwaniami.</strong>",
					],
				},
			},
		],
	},
	sectionStats: {
		texts: [
			"Ze względu na szeroki i indywidualny od potrzeb Klienta zakres usług oraz formę współpracy ostateczną wartość oferty uzależniona jest od zakresu zleconych czynności i <strong>jest indywidualnie negocjowana z właścicielami nieruchomości.</strong>",
			"Cennik dotyczący usług biura rachunkowego jest przygotowywany indywidualnie od zakresu czynności i oczekiwań Klienta.",
		],
		statistics: [
			{
				icon: <BsFillPeopleFill />,
				value: 100,
				unit: "Zadowolonych Klientów",
			},
			{
				icon: <IoBuild />,
				value: 10,
				unit: "Administrowanych inwestycji",
			},

			{
				icon: <BsFillBriefcaseFill />,
				value: 50,
				unit: "Partnerów biznesowych",
			},
		],
	},
	sectionTeam: {
		team: [
			{
				image: "section-team-1.jpg",
				name: "Elizabeth Olsen",
				role: "CEO",
			},
			{
				image: "section-team-2.jpg",
				name: "Zoe Kravitz",
				role: "Co-Founder",
			},
			{
				image: "section-team-3.jpg",
				name: "Jenna Fischer",
				role: "HR Representive",
			},
		],
	},
};
