import "./scroll-up.scss";

import React from "react";
import { MdKeyboardArrowUp } from "react-icons/md";

const ScrollUp = ({ scrolled }) => {
	if (scrolled) {
		return (
			<a href="#" className="scroll-up">
				<MdKeyboardArrowUp className="scroll-up__icon" />
			</a>
		);
	}
	return null;
};

export default ScrollUp;
