import "./header.scss";

import React from "react";
import classNames from "classnames";
import { Link } from "gatsby";

import MobileMenu from "./MobileMenu";

import { nav } from "../../data/nav";

const Header = ({ scrolled }) => {
	return (
		<>
			<header className={classNames({ scrolled: scrolled })}>
				<div className="container">
					<div className="header">
						<Link to="/">
							<img
								src={
									require("../../assets/images/icons/logo-white.png")
										.default
								}
								alt="ADProfit"
								className="header__logo"
							/>
						</Link>
						<nav className="header__navigation header__navigation--desktop">
							<ul className="navigation">
								{nav.items.map((item, index) => (
									<li className="navigation__item" key={index}>
										<a href={item.link} title={item.label}>
											{item.label}
										</a>
									</li>
								))}
							</ul>
						</nav>
						<MobileMenu className="header__navigation--mobile" />
					</div>
				</div>
			</header>
			<div className="header-spacer" />
		</>
	);
};

export default Header;
