import "./statistic-counter.scss";

import React from "react";
import classNames from "classnames";
import ReactVisibilitySensor from "react-visibility-sensor";
import { IconContext } from "react-icons";
import CountUp from "react-countup";

const StatisticCounter = ({ icon, value, unit, white }) => {
	return (
		<IconContext.Provider
			value={{ color: "#ad5448", className: "react-icon" }}
		>
			<ReactVisibilitySensor>
				{({ isVisible }) => (
					<div
						className={classNames("statistic-counter", {
							"statistic-counter--white": white,
						})}
					>
						<div className="row">
							<div className="col-5 d-flex justify-content-center">
								{icon}
							</div>
							<div className="col-7">
								<div className="statistic-counter__content">
									<span className="statistic-counter__value">
										{isVisible ? (
											<CountUp
												end={value}
												duration={Math.ceil(value / 100)}
												suffix="+"
											/>
										) : (
											0
										)}
									</span>
									<span className="statistic-counter__unit">
										{unit}
									</span>
								</div>
							</div>
						</div>
					</div>
				)}
			</ReactVisibilitySensor>
		</IconContext.Provider>
	);
};

export default StatisticCounter;
