import "../styles/common/main.scss";
import "../styles/pages/page-home.scss";

import React, { useState, useEffect } from "react";
import classNames from "classnames";

import { Fade } from "react-awesome-reveal";
import { useMousePosition } from "../hooks/useMousePosition";

import { BsClockFill, BsFillTelephoneFill, BsPhoneFill } from "react-icons/bs";
import { GrMail } from "react-icons/gr";
import { FaMapMarkerAlt } from "react-icons/fa";

import { home } from "../data/pages/home";

import Layout from "../components/layout/Layout";
import Meta from "../components/common/Meta";

import IconTile from "../components/common/IconTile";
import SectionTitle from "../components/common/SectionTitle";
import ServiceTile from "../components/common/ServiceTile";
import Modal from "../components/common/Modal";

import Hero from "../components/pages/home/Hero";
import ClientSlider from "../components/pages/home/ClientSlider";
import StatisticCounter from "../components/pages/home/StatisticCounter";
// import TeamMemberTile from "../components/pages/home/TeamMemberTile";

const Home = () => {
	const [modal, setModal] = useState("");
	const { x, y, handleMouseMove } = useMousePosition();

	useEffect(() => {
		const body = document.querySelector("body");
		modal !== ""
			? (body.style.overflow = "hidden")
			: (body.style.overflow = "auto");
	}, [modal]);
	return (
		<div>
			<Layout>
				<Meta
					title="Zarządzanie nieruchomościami oraz księgowość Kraków | ADProfit | Strona główna"
					description="Kompleksowe i profesjonalne usługi z zakresu: księgowość oraz zarządzania nieruchomościami wykonywane przez licencjonowanych zarządców. Kraków ADProfit"
				/>
				<Hero />
				<section className="icon-group">
					<div className="container">
						<div className="row justify-content-center">
							{home.iconGroup.map((item, index) => (
								<div className="col-lg-4 col-md-5 col-sm-6" key={index}>
									<div>
										<Fade
											triggerOnce
											direction="up"
											delay={250 + 250 * index}
										>
											<IconTile
												icon={item.icon}
												title={item.title}
												text={item.text}
												iconIndex={index}
											/>
										</Fade>
									</div>
								</div>
							))}
						</div>
					</div>
				</section>

				<section className="about">
					<span className="anchor" id="o-nas" />
					<div className="container">
						<div className="row">
							<div className="col-md-6">
								<SectionTitle
									subtitle="O nas"
									title="Usługi wysokiej jakości"
									variant="right"
								/>
								<div
									dangerouslySetInnerHTML={{
										__html: home.sectionAbout.first.text,
									}}
								/>
							</div>
							<div className="col-md-6">
								<Fade direction="right" triggerOnce fraction={0.5}>
									<img
										src={
											require("../assets/images/photos/section-image-1.jpg")
												.default
										}
										alt=""
										className="img-fluid paragraph-img"
										loading="lazy"
									/>
								</Fade>
							</div>
						</div>
						<div className="row">
							<div className="col-md-6 order-md-1 order-2">
								<Fade direction="left" triggerOnce fraction={0.5}>
									<img
										src={
											require("../assets/images/photos/section-image-2.jpg")
												.default
										}
										alt=""
										className="img-fluid paragraph-img"
										loading="lazy"
									/>
								</Fade>
							</div>
							<div
								className="col-md-6 order-md-2 order-1"
								style={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									justifyContent: "space-between",
								}}
							>
								<div className="paragraph-container">
									<div
										dangerouslySetInnerHTML={{
											__html: home.sectionAbout.second.text,
										}}
									/>
								</div>
								<blockquote>
									{home.sectionAbout.second.blockquote}
								</blockquote>
							</div>
						</div>
					</div>
				</section>

				<section className="services">
					<span className="anchor" id="uslugi" />
					<div className="container">
						<SectionTitle
							subtitle="Nasze usługi"
							title="Poznaj naszą ofertę"
							variant="center"
						/>
						<p className="paragraph services__paragraph">
							{home.sectionServices.intro}
						</p>
						<div className="row">
							{home.sectionServices.services.map((item, index) => {
								return (
									<div className="col-md-4" key={index}>
										<ServiceTile
											image={item.image}
											icon={item.icon}
											title={item.title}
											text={item.text}
											onClick={() => setModal(item.id)}
										/>
									</div>
								);
							})}
						</div>
					</div>
				</section>

				<>
					{home.sectionServices.services.map((item) => (
						<Modal
							className={classNames({
								"modal--hidden": modal !== item.id,
							})}
							close={() => setModal("")}
							title={item.title}
							key={item.id}
						>
							{item.modal.texts.map((item, index) => (
								<p
									className="paragraph"
									key={index}
									dangerouslySetInnerHTML={{ __html: item }}
								/>
							))}
						</Modal>
					))}
				</>

				<section className="clients">
					<span className="anchor" id="klienci" />
					<div className="container">
						<ClientSlider />
					</div>
				</section>

				{/* <section className="why-us">
					<div className="container">
						<div className="row">
							<div className="col-lg-5" style={{ padding: "15px 0" }}>
								<SectionTitle
									subtitle="Dlaczego my"
									title="Lorem ipsum"
									variant="left"
									white
								/>
								<p className="paragraph inverse">
									Lorem ipsum dolor sit amet consectetur adipisicing
									elit. Obcaecati sunt quae ratione impedit nisi error
									enim minima vel molestias! Saepe, alias omnis
									exercitationem molestias voluptas ea sunt labore odio
									perferendis. Earum, quas. Nostrum sunt nulla
									accusantium odio quaerat hic autem cum laboriosam
									totam pariatur voluptatum libero illum tenetur esse
								</p>
								<ul className="why-us__list">
									<li className="why-us__list-item">
										<h5></h5>
										<span></span>
									</li>
									<li className="why-us__list-item">
										<h5></h5>
										<span></span>
									</li>
									<li className="why-us__list-item">
										<h5></h5>
										<span></span>
									</li>
								</ul>
							</div>
							<div className="col-lg-6 offset-lg-1">
								<img
									src={
										require("../assets/images/photos/section-why-us-image-1.jpg")
											.default
									}
									alt=""
									className="img-fluid"
								/>
							</div>
						</div>
					</div>
				</section> */}

				<section
					className="stats"
					style={{
						backgroundPosition: `calc(50% - ${x * 3}px) calc(50% - ${
							y * 3
						}px)`,
					}}
					onMouseMove={handleMouseMove}
				>
					<span className="anchor" id="cennik" />
					<div className="container">
						<div className="stats__content">
							<div className="row justify-content-center">
								<div className="col-lg-6">
									<SectionTitle
										subtitle="Cennik"
										title="Spersonalizowanie i przystępnie"
										variant="center"
										white
									/>
									{home.sectionStats.texts.map((item, index) => (
										<p
											className="paragraph inverse"
											key={index}
											dangerouslySetInnerHTML={{ __html: item }}
										/>
									))}

									<h3
										className="inverse"
										style={{
											textAlign: "center",
											marginTop: "60px",
											marginBottom: "-30px",
											fontWeight: "700",
										}}
									>
										Zaufało nam już
									</h3>
								</div>
							</div>
							<div className="stats__row">
								<div className="row justify-content-center">
									{home.sectionStats.statistics.map((item, index) => (
										<div
											className={classNames("col-md-3", {
												"offset-md-1": index !== 0,
											})}
											key={index}
										>
											<StatisticCounter
												icon={item.icon}
												value={item.value}
												unit={item.unit}
												white
											/>
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
				</section>

				{/* <section className="team">
					<span className="anchor" id="zespol" />
					<div className="container">
						<div className="row">
							<div className="col-md-6">
								<SectionTitle
									subtitle="Nasz zespół"
									title="Tworzymy ADProfit"
									variant="left"
								/>
							</div>
							<div className="col-md-6">
								<p className="paragraph">
									Lorem ipsum dolor sit amet consectetur adipisicing
									elit. Inventore mollitia reiciendis cum voluptatem,
									tempore minus unde illum quia! Cupiditate, totam! Sit
									consequatur iure deleniti sunt officiis dolorem eum
									natus aperiam.
								</p>
							</div>
						</div>
						<div className="row team__row">
							{home.sectionTeam.team.map((item, index) => (
								<div className="col-md-4" key={index}>
									<TeamMemberTile
										image={item.image}
										name={item.name}
										role={item.role}
									/>
								</div>
							))}
						</div>
					</div>
				</section> */}

				<section className="contact">
					<span className="anchor" id="kontakt" />
					<div className="container">
						<SectionTitle
							subtitle="Kontakt"
							title="Porozmawiajmy o twojej inwestycji"
							variant="center"
						/>
						<div className="row justify-content-center">
							<div className="col-lg-4 col-md-5 col-sm-6">
								<h4 className="contact__subtitle">
									<BsClockFill className="contact__icon" /> Godziny
									pracy
								</h4>
								<div className="row">
									<div className="col-4">
										<ul className="contact__list">
											<li>Poniedziałek</li>
											<li>Wtorek</li>
											<li>Środa</li>
											<li>Czwartek</li>
											<li>Piątek</li>
											<li>Sobota</li>
											<li>Niedziela</li>
										</ul>
									</div>
									<div className="col-7">
										<ul className="contact__list contact__list--odd">
											<li>9.00 - 17.00</li>
											<li>9.00 - 17.00</li>
											<li>9.00 - 17.00</li>
											<li>9.00 - 17.00</li>
											<li>9.00 - 17.00</li>
											<li>nieczynne</li>
											<li>nieczynne</li>
										</ul>
									</div>
								</div>
							</div>
							<div className="col-lg-4 col-md-5 col-sm-6">
								<h5 className="contact__subtitle">
									<BsFillTelephoneFill className="contact__icon" />{" "}
									Dane kontaktowe
								</h5>
								<ul className="contact__list">
									<li>
										<a href="tel:+48786179471" title="Telefon">
											<BsPhoneFill className="contact__small-icon" />{" "}
											786 179 471
										</a>
									</li>
									<li>
										<a href="tel:+48696452718" title="Telefon">
											<BsPhoneFill className="contact__small-icon" />{" "}
											696 452 718
										</a>
									</li>
									<li>
										<a
											href="mailto:biuro.gromadzka22c@gmail.com"
											title="Adres e-mail"
										>
											<GrMail className="contact__small-icon" />{" "}
											biuro.gromadzka22c@gmail.com
										</a>
									</li>
									<li>
										<a
											href="https://www.google.com/maps/place/AD+Profit+sp.+z+o.o./@50.0423335,19.9768671,17z/data=!3m1!4b1!4m6!3m5!1s0x4716454a1e2c8713:0x74c857449b9767ab!8m2!3d50.0423335!4d19.979442!16s%2Fg%2F11v19bz205?entry=ttu"
											title="Adres"
										>
											<FaMapMarkerAlt className="contact__small-icon" />{" "}
											ul. Gromadzka 22c, 30-714 Kraków
										</a>
									</li>
								</ul>
								<hr />
								<div className="row">
									<div className="col-3">
										<ul className="contact__list">
											<li>NIP</li>
											<li>REGON</li>
											<li>KRS</li>
										</ul>
									</div>
									<div className="col-9">
										<ul className="contact__list contact__list--odd">
											<li>6793216903</li>
											<li>388904910</li>
											<li>000899697</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</Layout>
		</div>
	);
};

export default Home;
