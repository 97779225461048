export const nav = {
	items: [
		{
			label: "O nas",
			link: "#o-nas",
		},
		{
			label: "Usługi",
			link: "#uslugi",
		},
		{
			label: "Cennik",
			link: "#cennik",
		},
		// {
		// 	label: "Zespół",
		// 	link: "#zespol",
		// },
		{
			label: "Kontakt",
			link: "#kontakt",
		},
	],
};
