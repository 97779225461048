import "./footer.scss";

import React from "react";

import { nav } from "../../data/nav";

const Footer = () => {
	const currentYear = new Date().getFullYear();

	return (
		<footer className="footer">
			<div className="container">
				<div className="row">
					<div
						className="col-md-3"
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<img
							src={
								require("../../assets/images/icons/logo-white.png")
									.default
							}
							alt="ADProfit"
							className="footer__logo"
							loading="lazy"
						/>
					</div>
					<div className="col-md-3 offset-md-1 margin-md">
						<h5 className="footer__subtitle">Menu</h5>
						<ul className="footer__nav">
							{nav.items.map((item, index) => (
								<li className="footer__nav-item" key={index}>
									<a href={item.link}>{item.label}</a>
								</li>
							))}
						</ul>
					</div>
					<div className="col-md-4 offset-md-1 margin-md">
						<h5 className="footer__subtitle">Kontakt</h5>
						<p className="footer__info">
							AD PROFIT Spółka z ograniczoną odpowiedzialnością
						</p>
						<p className="footer__info">ul. Gromadzka 22c</p>
						<p className="footer__info">30-714 Kraków</p>
					</div>
				</div>
				<hr />
				<div className="row">
					<div className="col-sm-6 footer__copy">
						<span>AD Profit &copy; {currentYear}</span>
					</div>
					<div className="col-sm-6 footer__copy footer__copy--right">
						<span>Projekt i wdrożenie</span>
						<a href="https://nxtweb.pl">
							<img
								src={
									require("../../assets/images/clients/nxtweb.svg")
										.default
								}
								alt="nxtweb."
							/>
						</a>
					</div>
				</div>
			</div>
			<img
				src={require("../../assets/images/photos/footer-bg.jpg").default}
				alt=""
				className="footer__bg-img"
				loading="lazy"
			/>
		</footer>
	);
};

export default Footer;
