import "./modal.scss";

import React from "react";
import { GrClose } from "react-icons/gr";

const Modal = ({ title, children, close, className }) => {
	return (
		<div className={`modal__bg ${className}`} onClick={close}>
			<div className="container">
				<div className="modal__popup">
					<h3 className="modal__title">{title}</h3>
					<button type="button" onClick={close} className="modal__close">
						<GrClose />
					</button>
					<hr />
					{children}
				</div>
			</div>
		</div>
	);
};

export default Modal;
