import "./hero.scss";

import React from "react";
import { Fade } from "react-awesome-reveal";

const Hero = () => {
	return (
		<section className="hero">
			<div className="hero__player">
				<video
					src={require("../../../assets/videos/hero-video.mp4").default}
					width="100%"
					height="100%"
					autoPlay
					loop
				/>
			</div>
			<div className="hero__container">
				<div className="container">
					<div className="hero__text inverse">
						<Fade direction="left" triggerOnce fraction={0.5}>
							<h2>Kompleksowe i profesjonalne usługi</h2>
						</Fade>
						<Fade direction="left" triggerOnce fraction={0.7}>
							<span>
								z zakresu księgowości oraz zarządzania nieruchomościami
							</span>
						</Fade>
					</div>
					<div className="hero__btn-group">
						<a className="btn btn--pulsing" href="#o-nas">
							Sprawdź
						</a>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Hero;
