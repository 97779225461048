import "./client-slider.scss";

import React from "react";
import Slider from "react-slick";

const ClientSlider = () => {
	const settings = {
		dots: true,
		arrows: false,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		initialSlide: 0,
		autoplay: true,
		pauseOnHover: true,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					infinite: true,
					dots: true,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					initialSlide: 2,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	const clients = [
		{
			name: "Carlos",
			url: "http://www.autoserwiscarlos.pl",
			image: "carlos.png",
		},
		{
			name: "MVK Cardan",
			url: "https://mvkcardan.pl",
			image: "mvk-cardan.png",
		},
		{
			name: "Okami Premium Detailing",
			url: "https://okami-detailing.pl",
			image: "okami.png",
		},
		{
			name: "Esencja Lunch Bar",
			url: "https://www.facebook.com/esencjalunchbar",
			image: "esencja.png",
		},
	];

	return (
		<div className="client-slider">
			<Slider {...settings}>
				{clients.map((client, index) => (
					<div key={index} className="client-slider__img-container">
						<a
							href={client.url}
							target="_blank"
							rel="noreferrer noopener nofollow"
						>
							<img
								src={
									require(`../../../assets/images/clients/${client.image}`)
										.default
								}
								alt={client.name}
								className="img-fluid client-slider__img"
								loading="lazy"
							/>
						</a>
					</div>
				))}
			</Slider>
		</div>
	);
};

export default ClientSlider;
